import { FirebaseAuthProvider, FirebaseDataProvider, RAFirebaseOptions } from "react-admin-firebase";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseOptions: RAFirebaseOptions = {
  // softDelete: true,
  associateUsersById: true,
  persistence: "session",
  lazyLoading: {
    enabled: false,
  },
  renameMetaFields: {
    created_at: "createdAt",
    created_by: "createdBy",
    updated_at: "updatedAt",
    updated_by: "updatedBy",
  },
  logging: false,
  transformToDb: (
    resourceName: string,
    documentData: any,
    documentId: string
  ) => {
    Object.keys(documentData).forEach((key) => {
      if (documentData[key] === undefined) {
        delete documentData[key];
      }
    });
    return documentData;
  },
};

export const authProvider = FirebaseAuthProvider(config, firebaseOptions);
export const dataProvider = FirebaseDataProvider(config, firebaseOptions);
