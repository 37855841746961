import { MultiLevelMenu } from "@react-admin/ra-navigation";

import DashboardIcon from "@mui/icons-material/Dashboard";
import { IssueIcon } from "../resources/issues";
import { DocumentIcon } from "../resources/documents/documents";
import { HandoverReportIcon } from "../resources/handoverReport/handoverReport";
import { MemberIcon } from "../resources/members";
import { categoryChoices } from "../resources/documents/CategoryChipsField";

const Menu = () => (
  <MultiLevelMenu>
    <MultiLevelMenu.Item
      key="dashboard"
      name="dashboard"
      to="/"
      label="Dashboard"
      icon={<DashboardIcon />}
    />
    <MultiLevelMenu.Item
      key="documents"
      name="documents"
      to="/documents"
      label="Info"
      icon={<DocumentIcon />}
    >
      {categoryChoices.map((cat) => (
        <MultiLevelMenu.Item
          key={"cat:" + cat.id}
          name={cat.id}
          to={`/documents?filter=%7B"category"%3A"${cat.id}"%7D`}
          label={cat.name}
        />
      ))}
    </MultiLevelMenu.Item>
    <MultiLevelMenu.Item
      key="handoverReports"
      name="handoverReports"
      to={"/handoverReport"}
      label="Handover Reports"
      icon={<HandoverReportIcon />}
    />
    <MultiLevelMenu.Item
      key="issues"
      name="issues"
      to={"/issues"}
      label="Issue Log"
      icon={<IssueIcon />}
    />
    <MultiLevelMenu.Item
      key="users"
      name="users"
      to="/users"
      label="Members"
      icon={<MemberIcon />}
    />
  </MultiLevelMenu>
);
export default Menu;
