import * as React from "react";
import { FC, createElement } from "react";
import {
  Card,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { Link, To } from "react-router-dom";
import { ReactNode } from "react";

interface Props {
  icon: FC<any>;
  title?: string;
  subtitle?: string | number;
  children?: ReactNode;
  to?: To;
  sx?: any;
}

const CardWithIcon = (props: Props) => {
  const { children, to, sx } = props;
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    // @ts-ignore
    <Card
      sx={{
        ...sx,
        minHeight: 52,
        display: "flex",
        flexDirection: "column",
        flex: isXSmall ? undefined : "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      {to ? (
        <Link to={to}>
          <BoxHeader {...props} />
        </Link>
      ) : (
        <BoxHeader {...props} />
      )}
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;

const BoxHeader = ({ icon, title, subtitle }: Props) => (
  <Box
    sx={{
      overflow: "inherit",
      padding: "16px",
      background: (theme) =>
        `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .icon": {
        color: (theme) =>
          theme.palette.mode === "dark" ? "inherit" : "#dc2440",
      },
    }}
  >
    <Box width="3em" className="icon">
      {createElement(icon, { fontSize: "large" })}
    </Box>
    <Box textAlign="right">
      <Typography color="textSecondary">{title}</Typography>
      <Typography variant="h5" component="h2">
        {subtitle || " "}
      </Typography>
    </Box>
  </Box>
);
