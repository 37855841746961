import { DataProvider } from "react-admin";
import { dataProvider as FbDataProvider } from "./firebase";
import { HandoverReportResource } from "./resources/handoverReport";
import { authProvider } from "./firebase";
import { MemberResource } from "./resources/members";

const UsersAPIEndpoint =
  "https://europe-west2-mandarin-syndicate.cloudfunctions.net/usermanager";

export const dataProvider: DataProvider = {
  ...FbDataProvider,
  getList: async (resource, params) => {
    switch (resource) {
      case MemberResource:
        const filters = Object.keys(params.filter)
          .map((key) => `${key}=${params.filter[key]}`)
          .join("&");
        const token = await authProvider.getJWTToken();
        let url = `${UsersAPIEndpoint}/v1/users?page=${params.pagination.page}&size=${params.pagination.perPage}`;
        if (filters) {
          url = url + `&${filters}`;
        }
        if (params.sort) {
          url =
            url +
            `&sorts=${params.sort.order === "DESC" ? "-" : ""}${
              params.sort.field
            }`;
        }
        return fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => res.json())
          .then((res) => ({
            data: res.data.users.map(toUser),
            total: res.data.users?.length || 0, // TODO: need total users from API
          }));
      default:
        return FbDataProvider.getList(resource, params);
    }
  },
  getOne: async (resource, params) => {
    switch (resource) {
      case MemberResource:
        const token = await authProvider.getJWTToken();
        return fetch(`${UsersAPIEndpoint}/v1/users/${params.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => res.json())
          .then((res) => ({
            data: toUser(res.data),
          }));
      default:
        return FbDataProvider.getOne(resource, params);
    }
  },
  getMany: async (resource, params) => {
    switch (resource) {
      case MemberResource:
        const token = await authProvider.getJWTToken();
        return Promise.all(
          params.ids.map((id) =>
            fetch(`${UsersAPIEndpoint}/v1/users/${id}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((res) => res.json())
              .then((res) => toUser(res.data))
          )
        ).then((res) => ({ data: res }));
      default:
        return FbDataProvider.getMany(resource, params);
    }
  },
  create: async (resource, params) => {
    switch (resource) {
      case MemberResource:
        const token = await authProvider.getJWTToken();
        return fetch(`${UsersAPIEndpoint}/v1/users`, {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify(params.data),
        })
          .then((res) => res.json())
          .then((res) => ({
            data: toUser(res.data),
          }));
      case HandoverReportResource:
        return FbDataProvider.create(resource, params).then(
          syncComments(resource, params)
        );
      default:
        return FbDataProvider.create(resource, params);
    }
  },
  update: async (resource, params) => {
    switch (resource) {
      case MemberResource:
        const token = await authProvider.getJWTToken();
        return fetch(`${UsersAPIEndpoint}/v1/users`, {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify(params.data),
        })
          .then((res) => res.json())
          .catch(console.error)
          .then((res) => ({
            data: toUser(res.data),
          }));
      case HandoverReportResource:
        return FbDataProvider.update(resource, params).then(
          syncComments(resource, params)
        );
      default:
        return FbDataProvider.update(resource, params);
    }
  },
  delete: async (resource, params) => {
    switch (resource) {
      case MemberResource:
        const token = await authProvider.getJWTToken();
        const user = await fetch(`${UsersAPIEndpoint}/v1/users/${params.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => res.json());
        return fetch(`${UsersAPIEndpoint}/v1/users`, {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify({
            ...toUser(user.data),
            disabled: true,
          }),
        })
          .then((res) => res.json())
          .catch(console.error)
          .then((res) => ({
            data: res.data && toUser(res.data),
          }));
      default:
        return FbDataProvider.delete(resource, params);
    }
  },
};
export default dataProvider;

const toUser = (u: any) => ({
  ...u,
  create_date: u.create_date ? new Date(u.create_date.seconds * 1000) : undefined,
  last_login: u.last_login ? new Date(u.last_login.seconds * 1000) : undefined,
});

const syncComments =
  (resource: string, params: { data: any }) => (report: any) => {
    const toCreate = params.data?.issues.map((i: any) => {
      if (i.issueCreatedInline || i.commentId) {
        return Promise.resolve(null);
      }
      return FbDataProvider.create("comments", {
        data: {
          issueId: i.issue,
          reportId: report.data.id,
          body: i.comment,
        },
      }).then((res: any) => res.data);
    });
    const toUpdate = params.data?.issues
      .filter((i: any) => !!i.commentId)
      .map((i: any) => {
        return FbDataProvider.update("comments", {
          id: i.commentId,
          data: {
            issueId: i.issue,
            reportId: report.data.id,
            body: i.comment,
          },
          previousData: {
            id: i.commentId,
          },
        }).then((res: any) => res.data);
      });
    return Promise.all(toCreate)
      .then((issueComments) => {
        report.data.issues = report.data.issues.map((issue: any, i: number) => {
          if (issueComments[i] === null) {
            return issue;
          }
          return {
            ...issue,
            commentId: issueComments[i].id,
          };
        });
        return FbDataProvider.update(resource, {
          id: report.data.id,
          data: report.data,
          previousData: params.data,
        });
      })
      .then((report) => Promise.all(toUpdate).then(() => report));
  };
