import React from "react";
import { Login, LoginForm } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import ForgotPasswordButton from "./ForgotPassword";

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "#/",
  // We will display Google and Facebook as auth providers.
  // signInOptions: [
  //   firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  //   firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  // ],
};

const SignInScreen = () => (
  <StyledFirebaseAuth firebaseAuth={firebase.auth()} uiConfig={uiConfig} />
);

const styles = {
  backgroundImage:
    "radial-gradient(circle at 50% 14em, #4D7BBD 0%, #3462A4 60%, #1A488A 100%)",
};

const LoginPage = (props: any) => (
  <Login sx={styles} {...props}>
    <div>
      <LoginForm {...props} />
      <SignInScreen />
      <ForgotPasswordButton {...props} />
    </div>
  </Login>
);

export default LoginPage;
