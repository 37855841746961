import UserIcon from "@mui/icons-material/Person";
import BookingsIcon from "@mui/icons-material/Event";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { Title, useGetList, useGetOne } from "react-admin";
import { Link } from "react-router-dom";
import CardWithIcon from "./CardWithIcon";
import Welcome from "./Welcome";
import { IssueIcon } from "../resources/issues";

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

export const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return isXSmall ? (
    <>
      <Title title="Firstmate - Mandarin" />
      <Welcome />
      <OpenIssues />
      <VerticalSpacer />
      <Bookings />
      <VerticalSpacer />
      <LastHandover />
      <VerticalSpacer />
      <MembersList />
    </>
  ) : (
    <>
      <Title title="Firstmate - Mandarin" />
      <Welcome />
      <div className="dashboard__grid">
        <div className="grid__item">
          <OpenIssues /> <Bookings />
        </div>
        <div className="grid__item">
          <LastHandover />
        </div>
        <div className="grid__item">
          <MembersList />
        </div>
      </div>
    </>
  );
};
export default Dashboard;

const OpenIssues = () => {
  const { total, isLoading } = useGetList("issues", {
    filter: {
      status: "open",
    },
  });
  return (
    <CardWithIcon
      to="/issues"
      icon={IssueIcon}
      title="Open Issues"
      subtitle={isLoading ? "-" : total}
      sx={{ marginBottom: ".5rem" }}
    />
  );
};

const LastHandover = () => {
  const { data, isLoading } = useGetList("handoverReport", {
    sort: {
      field: "handoverDate",
      order: "DESC",
    },
  });
  const { data: user, isLoading: usersLoading } = useGetOne("users", {
    id: data && data.length && data[0].host,
  });

  const subtitle =
    isLoading || usersLoading || !user || !data
      ? "-"
      : `${user.display_name} on ${new Date(
          data[0].handoverDate
        ).toLocaleDateString("en-GB")}`;
  return (
    <CardWithIcon
      to="/"
      icon={UserIcon}
      title="Last Handover"
      subtitle={subtitle}
    >
      <Button
        sx={{ borderRadius: 0 }}
        component={Link}
        to="/handoverReport/create"
        size="small"
        color="primary"
      >
        <Box p={1} sx={{ color: "primary.main" }}>
          New Handover
        </Box>
      </Button>
    </CardWithIcon>
  );
};

const MembersList = () => {
  const { data, isLoading } = useGetList("users", {
    sort: { field: "last_login", order: "DESC" },
    filter: { disabled: false } 
  });
  // TODO: filtering disabled users not working atm so manually filtering for now
  const total = data?.filter((data) => !data.disabled).length;
  return (
    <CardWithIcon to="/" icon={UserIcon} title="Members" subtitle={total}>
      <List sx={{ display: "block" }}>
        {isLoading || !data
          ? null
          : data
              .filter((data) => !data.disabled)
              .slice(0, data.length < 5 ? data.length : 5)
              .map((record: any) => (
                <ListItem
                  to={`/users/${record.id}`}
                  component={Link}
                  key={record.id}
                >
                  <ListItemAvatar>
                    <Avatar src={`${record.avatar}?size=32x32`} />
                  </ListItemAvatar>
                  <ListItemText primary={record.display_name} />
                </ListItem>
              ))}
      </List>
      <Box flexGrow={1}>&nbsp;</Box>
      <Button
        sx={{ borderRadius: 0 }}
        component={Link}
        to="/users"
        size="small"
        color="primary"
      >
        <Box p={1} sx={{ color: "primary.main" }}>
          See All Members
        </Box>
      </Button>
    </CardWithIcon>
  );
};

const Bookings = () => {
  const { data, isLoading } = useGetList("documents", {
    filter: { category: "bookings" },
    pagination: { perPage: 1, page: 1 },
    sort: { field: "createdAt", order: "DESC" },
  });
  function handleClick() {
    data && window.open(data[0].file?.src, "_blank");
  }
  return !isLoading && data ? (
    <CardWithIcon icon={BookingsIcon} title="Bookings" subtitle={data[0].season}>
      <Button
        sx={{ borderRadius: 0 }}
        onClick={handleClick}
        size="small"
        color="primary"
      >
        <Box p={1} sx={{ color: "primary.main" }}>
          View Bookings
        </Box>
      </Button>
    </CardWithIcon>
  ) : null;
};
