import * as React from "react";
import { Fragment, useCallback } from "react";
import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  SelectArrayInput,
  useRecordContext,
  SingleFieldList,
  ReferenceManyField,
  ReferenceField,
  SelectInput,
  Toolbar,
  SaveButton,
  useCreate,
  RaRecord,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  useShowContext,
  useListContext,
  DatagridConfigurable,
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
  RecordContextProvider,
  useCreatePath,
  useResourceContext,
  CreateButton,
  EditButton,
  Loading,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  Grid,
  Typography,
  Chip,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  useMediaQuery,
  Tabs,
  Tab,
  Theme,
  Box,
} from "@mui/material";
import { useFormDialogContext } from "@react-admin/ra-form-layout";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AdjustIcon from "@mui/icons-material/Adjust";
import RichTextInput from "../fields/RichTextInput";
import TimeAgoField from "../fields/TimeAgoField";
import { ChipArrayField } from "../fields/ChipField";
import IfCanAccess from "../auth/IfCanAccess";
import CommentCreateForm from "./issues/CommentCreate";
import CommentCard from "./issues/CommentCard";

export const IssueIcon = AnnouncementIcon;
export const IssueResource = "issues";

const labels = [
  { name: "Fault", id: "fault" },
  { name: "Missing Item", id: "missing-item" },
  { name: "Maintenance", id: "maintenance" },
  { name: "Improvement", id: "improvement" },
];

const statuses = [
  { name: "Open", id: "open" },
  { name: "Closed", id: "closed" },
];

const issueFilters = [
  <SelectInput source="labels" choices={labels} />,
  <ReferenceInput reference="users" source="createdBy" label="Author" />,
  <ReferenceInput reference="users" source="assignee" label="Assignee" />,
];

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
  </TopToolbar>
);

export const IssueList = () => (
  <List
    filterDefaultValues={{ status: statuses[0].id }}
    filters={issueFilters}
    actions={<ListActions />}
  >
    <TabbedDatagrid />
  </List>
);

const IssueTitle = () => {
  const record = useRecordContext();
  return <span>Issue {record ? `"${record.title}"` : ""}</span>;
};

const IssueShowActions = () => (
  <TopToolbar>
    <IfCanAccess action="edit">
      <EditButton />
    </IfCanAccess>
    <CreateButton label="issues.create" />
  </TopToolbar>
);

export const IssueShow = (props: any) => (
  <Show
    sx={{ "& .RaShow-card": { background: "none", boxShadow: "none" } }}
    actions={<IssueShowActions />}
    {...props}
  >
    <SimpleShowLayout>
      <IssueLayout />
    </SimpleShowLayout>
  </Show>
);

const IssueLayout = (props: any) => {
  const { record, isLoading } = useShowContext();
  return isLoading ? null : (
    <Grid container spacing={2} rowSpacing={3}>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="h4">{record?.title}</Typography>
        </div>
        <div>
          <IssueStatusField withLabel={true} source="status" />
          &nbsp;
          <ReferenceField link={false} reference="users" source="createdBy" />
          &nbsp;
          <Typography variant="body2" sx={{ display: "inline-block" }}>
            opened this issue
          </Typography>
          &nbsp;
          <TimeAgoField
            source="createdAt"
            variant="body2"
            sx={{ display: "inline-block" }}
          />
        </div>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Divider />
      </Grid>
      <Grid xs={12} sm={6} md={8} lg={8} xl={8} item>
        <div className="timeline">
          <CommentCard bodySource="comment" enableDelete={false} />
          <ReferenceManyField
            reference="comments"
            target="issueId"
            sort={{ order: "ASC", field: "createdAt" }}
          >
            <SingleFieldList sx={{ display: "inherit" }} linkType={false}>
              <CommentCard />
            </SingleFieldList>
          </ReferenceManyField>
        </div>
        <CommentCreateForm />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        sx={{ marginBottom: "1rem" }}
        item
      >
        <Typography variant="subtitle2">Assignee</Typography>
        <ReferenceField
          reference="users"
          source="assignee"
          label="Assignee"
          emptyText="No one assigned"
          link={false}
        />
        <Divider sx={{ margin: "1rem 0" }} />
        <Typography variant="subtitle2">Labels</Typography>
        <ChipArrayField source="labels" choices={labels} />
      </Grid>
    </Grid>
  );
};

export const IssueEdit = (props: any) => (
  <Edit title={<IssueTitle />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <SelectArrayInput source="labels" choices={labels} />
      <ReferenceInput reference="users" source="assignee" />
      <RichTextInput label="Description" source="comment" />
    </SimpleForm>
  </Edit>
);

export const IssueCreate = (props: any) => (
  <Create title="Add an Issue" {...props}>
    <IssueForm />
  </Create>
);

const IssueToolbar = () => (
  <Toolbar>
    <SaveButton label="Add Issue" />
  </Toolbar>
);

export const IssueForm = (props: any) => {
  const [create] = useCreate();
  const dialog = useFormDialogContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const issueSave = (data: RaRecord) => {
    create(
      "issues",
      { data },
      {
        onSettled: (createRes) => {
          if (props.onSubmit) {
            props.onSubmit(createRes);
          }
          if (dialog) {
            dialog.close();
          } else {
            redirect("list", IssueResource);
            notify(`${IssueResource}.notifications.create`, { type: "success" });
          }
        },
      }
    );
  };

  return (
    <SimpleForm toolbar={<IssueToolbar />} {...props} onSubmit={issueSave}>
      <SelectInput
        style={{ display: "none" }}
        hidden
        choices={statuses}
        source="status"
        defaultValue="open"
      />
      <TextInput source="title" />
      <SelectArrayInput source="labels" choices={labels} />
      <RichTextInput source="comment" />
    </SimpleForm>
  );
};

const IssueStatusField = ({ source, withLabel, ...props }: any) => {
  const record = useRecordContext();
  let icon;
  switch (record.status) {
    case "open":
      icon = <AdjustIcon color="primary" sx={{ marginRight: ".5rem" }} />;
      break;
    default:
      icon = <TaskAltIcon color="success" sx={{ marginRight: ".5rem" }} />;
  }
  if (withLabel) {
    switch (record.status) {
      case "open":
        return <Chip color="primary" icon={icon} label="Open" {...props} />;
      default:
        return <Chip color="success" icon={icon} label="Closed" {...props} />;
    }
  }
  return icon;
};

const TabbedDatagrid = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters, data, isLoading } =
    listContext;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  const createPath = useCreatePath();
  const resource = useResourceContext();

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        textColor="secondary"
        indicatorColor="secondary"
        onChange={handleChange}
      >
        {statuses.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        <Box margin="0.5em">
          {isLoading && <Loading />}
          {!isLoading &&
            data.map((record) => (
              <RecordContextProvider key={record.id} value={record}>
                <Card sx={{ margin: "0.5rem 0" }}>
                  <CardActionArea
                    href={
                      "#" +
                      createPath({ type: "show", resource, id: record.id })
                    }
                  >
                    <CardHeader
                      title={
                        <span style={{ display: "flex", alignItems: "middle" }}>
                          <IssueStatusField source="status" label="Status" />
                          <TextField source="title" variant="body1" />
                        </span>
                      }
                      titleTypographyProps={{ variant: "body1" }}
                    />
                    <CardContent sx={{ pt: 0 }}>
                      <Typography variant="body2" gutterBottom>
                        Issue #
                        <TextField source="id" variant="body2" />
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Created:&nbsp;
                        <TimeAgoField
                          source="createdAt"
                          variant="body2"
                          gutterBottom
                          component="span"
                        />
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Author:&nbsp;
                        <ReferenceField
                          reference="users"
                          source="createdBy"
                          label="Author"
                          link={false}
                        />
                      </Typography>
                      <ChipArrayField source="labels" choices={labels} />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </RecordContextProvider>
            ))}
        </Box>
      ) : (
        <DatagridConfigurable rowClick="show">
          <IssueStatusField source="status" label="Status" />
          <TextField source="title" label="Title" />
          <TimeAgoField source="createdAt" label="Created" variant="body2" />
          <ReferenceField reference="users" source="createdBy" label="Author" link={false} />
          <ChipArrayField source="labels" choices={labels} />
        </DatagridConfigurable>
      )}
    </Fragment>
  );
};
