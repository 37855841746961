import englishMessages from "ra-language-english";
import { TranslationMessages } from "react-admin";

const messages: TranslationMessages = {
  ...englishMessages,
  issues: {
    create: "New issue",
    action: {
      open: "Reopen issue",
      close: "Mark issue as closed",
      commentClose: "Mark as closed & comment",
      commentOpen: "Reopen & comment",
    },
    notifications: {
      create: "Issue created"
    }
  },
  action: {
    open: {
      success: "'%{resource}' reopened",
      error: "Error reopening issue: %{error}",
    },
    close: {
      success: "'%{resource}' closed",
      error: "Error closing issue: %{error}",
    },
  },
  resources: {
    comments: {
      name: "comment",
    },
  },
  auth: {
    reset: {
      link: "Forgot Password?",
      dialogTitle: "Send Password Reset?",
      dialogText: "A password reset will be sent to the following email:",
      dialogAction: "Send email",
      confirmMessage: "Password reset email sent!"
    }
  }
};
messages.ra.auth.username = "Email";
messages.ra.message.delete_title = "Delete %{name}";

export default messages;
