import { ChipField, useRecordContext } from "react-admin";

const rangeOfYears = (start: number, end: number) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

export const seasonChoices = rangeOfYears(
  new Date("Jan 1 2021").getFullYear(),
  new Date().getFullYear() + 1
).map((year) => ({
  name: year,
  id: year,
}));

export default seasonChoices;

export const seasonColor = (
  season: number
): "success" | "info" | "warning" | "default" => {
  switch (season) {
    case new Date().getFullYear():
      return "success";
    case new Date().getFullYear() + 1:
      return "info";
    case new Date().getFullYear() - 1:
      return "warning";
    default:
      return "default";
  }
};

export const SeasonField = ({ source }: any) => {
  const record = useRecordContext();
  return (
    record[source] ? <ChipField
      size="small"
      color={seasonColor(record[source])}
      sx={{ padding: "1px", margin: "0" }}
      source="season"
    /> : null
  );
};
