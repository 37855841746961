import { useGetIdentity, useRecordContext } from "react-admin";

const IfCanAccess = ({ children, action }: any) => {
  const { identity, isLoading } = useGetIdentity();
  const record = useRecordContext();

  if (
    isLoading ||
    ((action === "edit" || action === "delete") &&
    identity?.id !== record?.createdBy)
  ) {
    return null;
  }
  return children;
};
export default IfCanAccess;
