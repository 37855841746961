import { useState } from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  TextInput,
  FileInput,
  FileField,
  useRecordContext,
  ReferenceField,
  useListContext,
  RecordContextProvider,
  CreateButton,
  TopToolbar,
  SavedQueriesList,
  FilterList,
  FilterListItem,
  SelectInput,
  Pagination,
  FilterButton,
  required,
} from "react-admin";
import { Grid, Card, CardContent, IconButton, Hidden } from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import { ViewList, ViewModule } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/LocalOffer";
import TimeIcon from "@mui/icons-material/AccessTime";
import DocumentTile, { DocumentContextMenu } from "./DocumentTile";
import seasonChoices, { SeasonField } from "./seasons";
import { categoryChoices } from "./CategoryChipsField";
import ChipField from "../../fields/ChipField";

export const DocumentIcon = BookIcon;

// Document list datagrid view
const DocumentDatagrid = (props: any) => {
  const { filterValues } = useListContext();
  return (
    <Datagrid {...props}>
      <TextField source="name" />
      <SeasonField
        source="season"
        sortable={filterValues.season ? false : true}
      />
      <ChipField
        size="small"
        source="category"
        choices={categoryChoices}
        sortable={false}
      />
      <DateField source="createdAt" label="Created" />
      <ReferenceField
        reference="users"
        source="createdBy"
        label="Author"
        sortable={false}
        link={false}
      />
      <DocumentContextMenu />
    </Datagrid>
  );
};

// Document list tiles view
const DocumentTiles = (props: any) => {
  const { data, isLoading } = useListContext(props);
  return (
    <Grid container>
      {isLoading
        ? null
        : data?.map((doc: any) => (
            <Grid key={doc.id} xs={12} sm={12} md={6} lg={4} xl={3} item>
              <RecordContextProvider value={doc}>
                <DocumentTile />
              </RecordContextProvider>
            </Grid>
          ))}
    </Grid>
  );
};

// Actions at top of list view with custom view switcher
const ListActions = ({ setList, list }: any) => (
  <TopToolbar>
    <CreateButton />
    <Hidden mdUp={true}>
      <FilterButton filters={listFilters} />
    </Hidden>
    <Hidden smDown={true}>
      {list ? (
        <IconButton
          size="small"
          aria-label="tiles"
          onClick={() => setList(false)}
        >
          <ViewModule />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          aria-label="list"
          onClick={() => setList(true)}
        >
          <ViewList />
        </IconButton>
      )}
    </Hidden>
  </TopToolbar>
);

// Sidebar for list view filters
export const ListSidebar = ({ compact }: any) => (
  <Hidden mdDown={true}>
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 250, flexShrink: 0 }}>
      <CardContent>
        <SavedQueriesList />
        <FilterList label="Season" icon={<TimeIcon />}>
          {seasonFilters.map((season) => (
            <FilterListItem label={season.name} value={{ season: season.id }} />
          ))}
        </FilterList>
        <FilterList label="Category" icon={<CategoryIcon />}>
          {categoryChoices.map((cat) => (
            <FilterListItem label={cat.name} value={{ category: cat.id }} />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  </Hidden>
);

const seasonFilters = [
  { name: "This year", id: new Date().getFullYear() },
  { name: "Next year", id: new Date().getFullYear() + 1 },
  { name: "Last year", id: new Date().getFullYear() - 1 },
];

const listFilters = [
  <SelectInput label="Catgeory" source="category" choices={categoryChoices} />,
  <SelectInput label="Season" source="season" choices={seasonFilters} />,
];

const DocumentPagination = ({ list, ...props }: any) => (
  <Pagination
    rowsPerPageOptions={list ? [10, 25, 50, 100] : [12, 24, 48, 96]}
    {...props}
  />
);

export const DocumentList = (props: any) => {
  const [list, setList] = useState(false);
  return (
    <List
      pagination={<DocumentPagination list={list} />}
      perPage={list ? 10 : 12}
      sx={{
        backgroundColor: "transparent",
        "& .RaList-content": {
          transition: "none",
          backgroundColor: list ? "" : "transparent",
          boxShadow: list ? "" : "none",
          marginTop: "8px",
        },
      }}
      {...props}
      filters={listFilters}
      actions={<ListActions setList={setList} list={list} />}
      aside={<ListSidebar compact={list} />}
    >
      {list ? <DocumentDatagrid /> : <DocumentTiles />}
    </List>
  );
};

const DocumentTitle = () => {
  const record = useRecordContext();
  return <span>Document {record ? `"${record.title}"` : ""}</span>;
};

export const DocumentEdit = (props: any) => (
  <Edit title={<DocumentTitle />} redirect="list" {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <SelectInput source="category" choices={categoryChoices} />
      <SelectInput source="season" choices={seasonChoices} />
      <FileInput source="file" accept="application/pdf" validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export const DocumentCreate = (props: any) => (
  <Create title="Create a Document" redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput source="category" choices={categoryChoices} />
      <SelectInput source="season" choices={seasonChoices} />
      <FileInput source="file" accept="application/pdf" validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
