import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  useRecordContext,
  useDeleteWithUndoController,
  useResourceContext,
  useCreatePath,
} from "react-admin";
import {
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { SeasonField } from "./seasons";
import ChipField from "../../fields/ChipField";
import TimeAgoField from "../../fields/TimeAgoField";
import { categoryChoices } from "./CategoryChipsField";
import IfCanAccess from "../../auth/IfCanAccess";

// Individial document tile for tiles list view
export const DocumentTile = () => {
  const record = useRecordContext();
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  return (
    <Card
      variant="outlined"
      sx={{
        margin: matches ? "0 1rem 1rem 0" : "0 0 1rem 0",
      }}
    >
      <CardActionArea href={record.file?.src} target="_blank">
        <CardContent>
          <Typography variant="h6">{record.name}</Typography>
          <br />
          <ChipField size="small" source="category" choices={categoryChoices} />
          {record.season && <SeasonField source="season" />}
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          "&": {
            padding: "1rem",
          },
        }}
      >
        <div style={{ flex: "1" }}>
          <Typography variant="body2" sx={{ color: "#999" }}>
            Created
          </Typography>
          <TimeAgoField source="createdAt" variant="body2" />
        </div>
        <div>
          <DocumentContextMenu />
        </div>
      </CardActions>
    </Card>
  );
};

export default DocumentTile;

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          marginRight: "1rem",
        },
      },
    },
  })
);

const ITEM_HEIGHT = 48;

export function DocumentContextMenu() {
  const record = useRecordContext();
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDownload = () => {
    record.file && window.open(record.file?.src, "_blank");
    handleClose();
  };
  const handleEdit = () => {
    navigate(createPath({ resource, type: "edit", id: record.id }));
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem key="download" onClick={handleDownload}>
          <DownloadIcon fontSize="small" />
          Download
        </MenuItem>
        <IfCanAccess action="edit" >
          <MenuItem key="edit" onClick={handleEdit}>
            <EditIcon />
            Edit Details
          </MenuItem>
        </IfCanAccess>
        <IfCanAccess action="delete" >
          <DeleteMenuItem handleClose={handleClose} />
        </IfCanAccess>
      </StyledMenu>
    </div>
  );
}

const DeleteMenuItem = ({ handleClose, ...props }: any) => {
  const {
    label = "Delete",
    className,
    icon = <DeleteIcon />,
    onClick,
    redirect = "list",
    mutationOptions,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const { isLoading, handleDelete } = useDeleteWithUndoController({
    record,
    resource,
    redirect,
    onClick,
    mutationOptions,
  });
  return (
    <MenuItem
      key="remove"
      disabled={isLoading}
      onClick={(event) => {
        handleDelete(event);
        handleClose(event);
      }}
      {...rest}
    >
      {icon}
      {label}
    </MenuItem>
  );
};
