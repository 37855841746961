import {
  SimpleForm,
  useRecordContext,
  Toolbar,
  SaveButton,
  useCreate,
  RaRecord,
  useRefresh,
} from "react-admin";
import { Card, CircularProgress } from "@mui/material";
import RichTextInput from "../../fields/RichTextInput";
import SaveCloseButton from "./SaveCloseButton";
import { useState } from "react";

const CommentCreateToolbar = ({
  issue,
  hasComment,
  setAction,
}: {
  hasComment: boolean;
  issue: RaRecord;
  setAction: any;
}) => {
  return (
    <Toolbar sx={{ backgroundColor: "transparent" }}>
      <SaveButton
        icon={<></>}
        disabled={!hasComment}
        onClick={() => setAction(false)}
        label="Comment"
        color="secondary"
        variant="contained"
      />
      &nbsp;
      <SaveCloseButton
        issue={issue}
        withComment={hasComment}
        setAction={setAction}
      />
    </Toolbar>
  );
};

export const CommentCreateForm = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate("comments");
  const [action, setAction] = useState("");
  const commentSave = (data: any) => {
    create(
      "comments",
      { data: { ...data, issueId: record.id, action: action || null } },
      {
        onSettled: () => {
          refresh();
        },
      }
    );
  };
  const [hasComment, setHasComment] = useState(false);
  return isLoading ? (
    <CircularProgress sx={{ margin: "1rem" }} />
  ) : (
    <Card sx={{ margin: "0 1rem" }}>
      <SimpleForm
        record={{}}
        toolbar={
          <CommentCreateToolbar
            hasComment={hasComment}
            setAction={setAction}
            issue={record}
          />
        }
        onSubmit={commentSave}
        onInput={(values) => {
          const innerText = (values?.nativeEvent as any)?.target?.innerText;
          setHasComment(innerText !== "" && innerText !== "\n");
        }}
      >
        <RichTextInput label="Leave a Comment" source="body" helperText={false}/>
      </SimpleForm>
    </Card>
  );
};
export default CommentCreateForm;
