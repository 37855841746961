import { ArrayField, SingleFieldList, FunctionField } from "react-admin";
import { Chip } from "@mui/material";

export const categoryChoices = [
  { name: "Bookings", id: "bookings" },
  { name: "Membership", id: "membership" },
  { name: "Technical", id: "tech" },
  { name: "AGM", id: "agm" },
  { name: "Certfication", id: "cert" },
  // { name: "Membership > Agreement", id: "membership/agreement" },
  // { name: "Membership > Member list", id: "membership/member-list" },
  // { name: "Membership > Associate form", id: "membership/associate-form" },
  // { name: "Technical > Operating manuals", id: "technical/operating-manual" },
  // { name: "Technical > Equipment manuals", id: "technical/equipment-manuals" },
  // {
  //   name: "Technical > Engineer's newsletter",
  //   id: "technical/engineers-newsletter",
  // },
  // { name: "AGM > Agenda", id: "agm/agenda" },
  // { name: "AGM > Minutes", id: "agm/minutes" },
  // { name: "AGM > Engineer's YE report", id: "agm/engineers-ye-report" },
  // { name: "AGM > Treasurer's YE report", id: "agm/treasurers-ye-report" },
];

export const CategoryChipsField = ({ source }: any) => (
  <ArrayField source={source}>
    <SingleFieldList>
      <FunctionField
        render={(cat: any) => (
          <Chip
            label={categoryChoices.find((c) => c.id === cat)?.name}
            size="small"
          />
        )}
      />
    </SingleFieldList>
  </ArrayField>
);
export default CategoryChipsField;
