import { defaultTheme } from "react-admin";
import grey from "@mui/material/colors/grey";
import red from "@mui/material/colors/red";
import { PaletteMode } from "@mui/material";

export const lightTheme = {
  ...defaultTheme,
  palette: {
    mode: "light" as PaletteMode,
    background: {
      default: grey[100],
    },
    neutral: {
      main: grey[700],
    },
    primary: {
      main: "#CA3141",
    },
    secondary: {
      main: "#1A488A",
    },
    success: {
      main: "#378577",
    },
    info: {
      main: "#5F87C1",
    },
    warning: {
      main: "#F1896B",
      contrastText: "#fff"
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
};

export const darkTheme = {
  ...defaultTheme,
  palette: {
    mode: "dark" as PaletteMode,
    primary: {
      main: "#CA3141",
    },
    secondary: {
      main: "#1A488A",
    },
  },
};

export default lightTheme;
