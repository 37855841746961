import React from "react";
import { Layout } from "react-admin";
import { AppLocationContext } from "@react-admin/ra-navigation";
import AppBar from "./AppBar";
import { ProfileProvider } from "../auth/Profile";
import Menu from "./Menu";

const MyLayout = (props: any) => (
  <ProfileProvider>
    <AppLocationContext>
      <Layout {...props} appBar={AppBar} menu={Menu} />
    </AppLocationContext>
  </ProfileProvider>
);

export default MyLayout;
