import React from "react";
import { UserMenu, MenuItemLink, Logout } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { useProfile } from "../auth/Profile";

const MyUserMenu = (props: any) => {
  const { profileVersion } = useProfile();

  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to="/profile"
        primaryText="Edit Profile"
        leftIcon={<SettingsIcon />}
      />
      <Logout />
    </UserMenu>
  );
};

export default MyUserMenu;
