import {
  SaveButton,
  useGetIdentity,
  useNotify,
  useUpdate,
} from "react-admin";
import { serverTimestamp } from "firebase/firestore";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IssueResource } from "../issues";

export const CloseButton = ({
  issue,
  withComment,
  setAction,
  ...props
}: any) => {
  const notify = useNotify();
  const [update] = useUpdate(IssueResource);
  const { identity } = useGetIdentity();

  const handleClose = () => {
    setAction("closed");
    return update(IssueResource, {
      id: issue.id,
      data: {
        ...issue,
        status: "closed",
        closedAt: serverTimestamp(),
        closedBy: identity?.id,
      },
    })
      .then(() => {
        notify("action.close.success", {
          type: "success",
          messageArgs: { resource: issue.title },
          undoable: false,
        });
      })
      .catch((error: any) => {
        notify("action.close.error", {
          type: "warning",
          messageArgs: { error: error },
          undoable: false,
        });
      });
  };

  const handleOpen = async () => {
    setAction("open");
    return update(IssueResource, {
      id: issue.id,
      data: {
        ...issue,
        status: "open",
        closedAt: null,
        closedBy: null,
      },
    })
      .then(() => {
        notify("action.open.success", {
          type: "success",
          messageArgs: {
            resource: issue.title,
          },
          undoable: false,
        });
      })
      .catch((error: any) => {
        notify("action.open.error", {
          type: "warning",
          messageArgs: { error: error },
          undoable: false,
        });
      });
  };

  return issue?.status === "open" ? (
    <SaveButton
      alwaysEnable
      variant="outlined"
      onClick={handleClose}
      label={withComment ? "issues.action.commentClose" : "issues.action.close"}
      color="neutral"
      icon={<TaskAltIcon color="success"/>}
      {...props}
    />
  ) : (
    <SaveButton
      alwaysEnable
      variant="outlined"
      onClick={handleOpen}
      label={withComment ? "issues.action.commentOpen" : "issues.action.open"}
      color="neutral"
      icon={<RestartAltIcon color="primary"/>}
      {...props}
    />
  );
};
export default CloseButton;
