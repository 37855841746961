import {
  ArrayField,
  FunctionField,
  SingleFieldList,
  useListContext,
  useRecordContext,
} from "react-admin";
import Chip from "@mui/material/Chip";

interface Choice {
  id: string;
  name: string;
}

export interface ChipFieldProps {
  source: string;
  choices: Choice[];
  sortable?: boolean;
  linkToFilter?: boolean;
  size?: "small" | "medium";
}

export const ChipField = ({
  source,
  choices,
  linkToFilter,
  size,
  ...props
}: ChipFieldProps) => {
  const { setFilters, filterValues } = useListContext();
  const record = useRecordContext(props);
  const src = record[source] as string;
  const choiceMatch = choices.find((c) => c.id === src || src?.includes(c.id));
  if (!choiceMatch) {
    return <div />;
  } else {
    return (
      <Chip
        label={choiceMatch.name}
        key={choiceMatch.id}
        size={size}
        onClick={() => {
          linkToFilter &&
            setFilters({ ...filterValues, [source]: choiceMatch.id }, {});
        }}
      />
    );
  }
};
export default ChipField;

export interface ChipArrayFieldProps {
  source: string;
  choices: { id: string; name: string }[];
}

export const ChipArrayField = ({
  source,
  choices,
  ...props
}: ChipArrayFieldProps) => (
  <ArrayField source={source} {...props}>
    <SingleFieldList>
      <FunctionField
        render={(cat: any) => (
          <Chip
            label={choices.find((c) => c.id === cat)?.name}
            size="small"
            sx={{ marginRight: ".5rem", marginTop: ".5rem" }}
          />
        )}
      />
    </SingleFieldList>
  </ArrayField>
);
