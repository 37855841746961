import * as React from "react";
import { Box, Card, Typography, CardActions, Button } from "@mui/material";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser";
import MenuBook from "@mui/icons-material/MenuBook";


import illustration from "./firstmate_dashboard.png";

const Welcome = () => {
  return (
    <Card
      sx={{
        background: "#CBDBEE",
        color: (theme) => theme.palette.secondary.main,
        padding: "20px",
        marginTop: 2,
        marginBottom: "1em",
      }}
    >
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            Welcome to Firstmate - the narrowboat syndicate admin.
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              A community space for members of the Mandarin syndicate, coming
              together to make life aboard Mandarin a breeze.
            </Typography>
          </Box>
          <CardActions
            sx={{
              padding: { xs: 0, xl: null },
              flexWrap: { xs: "wrap", xl: null },
              "& a": {
                marginTop: { xs: "1em", xl: null },
                marginLeft: { xs: "0!important", xl: null },
                marginRight: { xs: "1em", xl: null },
              },
            }}
          >
            <Button
              variant="contained"
              href="https://firebasestorage.googleapis.com/v0/b/mandarin-syndicate.appspot.com/o/documents%2FxWIiU268FjiaSdMBF34W%2Ffile.pdf?alt=media&token=8a12292d-f1b8-4000-9c98-26256b6af9db"
              target="_blank"
              startIcon={<MenuBook />}
            >
              Getting started
            </Button>
            <Button
              variant="contained"
              href="https://mandarinsyndicate.co.uk"
              startIcon={<OpenInBrowser />}
              target="_blank"
            >
              View site
            </Button>
          </CardActions>
        </Box>
        <Box
          display={{ xs: "none", sm: "none", md: "block" }}
          sx={{
            background: `url(${illustration}) top right / cover`,
            marginLeft: "auto",
          }}
          width="24em"
          height="11em"
          overflow="hidden"
        />
      </Box>
    </Card>
  );
};

export default Welcome;
