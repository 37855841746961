import * as React from "react";
import {
  useContext,
  useState,
  createContext,
  useMemo,
  useCallback,
} from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {
  useTheme,
  useNotify,
  useGetIdentity,
  useAuthProvider,
  Title,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  UserIdentity,
} from "react-admin";
import { ReactAdminFirebaseAuthProvider } from "react-admin-firebase/dist/providers/AuthProvider";

import { darkTheme, lightTheme } from "../theme";

const ProfileContext = createContext<{
  profileVersion: number;
  refreshProfile: () => void;
}>({
  profileVersion: 0,
  refreshProfile: () => {},
});

export const ProfileProvider = ({ children }: any) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

const ProfileEdit = ({ ...props }) => {
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const { refreshProfile } = useProfile();
  const { isLoading, identity } = useGetIdentity();
  const authProvider = useAuthProvider() as ReactAdminFirebaseAuthProvider
  const [theme, setTheme] = useTheme();

  const mapIdentityToForm = ({id, fullName, avatar}: UserIdentity) => ({
    id,
    avatar,
    displayName: fullName,
  });

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      authProvider.getJWTToken().then((token) => {
        return fetch(
          `https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyBlXZ7_7PdW1Npd9ySoVi3ARR2w83tCJvg`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              idToken: token,
              ...values
            }),
          }
        )
      })
        .then((response) => response.json())
        .then((data) => {
          setSaving(false);
          notify("Your profile has been updated", {
            type: "info",
          });
          refreshProfile();
        })
        .catch((error) => {
          setSaving(false);
          notify("A technical error occured while updating your profile. Please try later.", {
              type: "warning"
          });
        });
    },
    [authProvider, notify, refreshProfile]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Title title="User Profile" />
      <Card>
        <SaveContextProvider value={saveContext}>
          <SimpleForm onSubmit={handleSave} record={identity ? mapIdentityToForm(identity) : {}}>
            <TextInput disabled source="id" />
            <TextInput source="displayName" />
            <Card raised={false} sx={{boxShadow: 'none'}}>
              <CardContent>
                <Box sx={{ width: "10em", display: "inline-block" }}>Theme</Box>
                <Button
                  variant="contained"
                  sx={{ margin: "1em" }}
                  color={theme?.palette?.mode === "light" ? "primary" : "secondary"}
                  onClick={() => setTheme(lightTheme)}
                >
                  Light
                </Button>
                <Button
                  variant="contained"
                  sx={{ margin: "1em" }}
                  color={theme?.palette?.mode === "dark" ? "primary" : "secondary"}
                  onClick={() => setTheme(darkTheme)}
                >
                  Dark
                </Button>
              </CardContent>
            </Card>
          </SimpleForm>
        </SaveContextProvider>
      </Card>
    </>
  );
};

export default ProfileEdit;
