import {
  RichTextInput as RaRichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  LinkButtons,
  ListButtons,
  RichTextInputProps,
} from "ra-input-rich-text";

export const RichTextInput = (props: RichTextInputProps) => {
  return (
    <RaRichTextInput
      fullWidth
      toolbar={
        <RichTextInputToolbar {...props}>
          <FormatButtons size="small" />
          <ListButtons size="small" />
          <LinkButtons size="small" />
        </RichTextInputToolbar>
      }
      {...props}
    />
  );
};

export default RichTextInput;
