import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
  EmailField,
  Toolbar,
  ToolbarProps,
  SaveButton,
  DeleteWithConfirmButton,
  BooleanField,
} from "react-admin";
import PeopleIcon from "@mui/icons-material/People";
export const MemberIcon = PeopleIcon;
export const MemberResource = "users";

export const MemberList = (props: any) => (
  <List {...props} pagination={false} perPage={100}>
    <Datagrid>
      <EmailField source="email" />
      <TextField source="display_name" label="Name" />
      {/* <SelectField source="roles" choices={roleChoices} /> */}
      <DateField source="create_date" label="Created at" locales="en-GB" />
      <DateField source="last_login" locales="en-GB" />
      <BooleanField source="disabled" label="Deactivated" />
      <EditButton />
    </Datagrid>
  </List>
);

const MemberTitle = () => {
  const record = useRecordContext();
  return <span>Member {record ? `"${record.display_name}"` : ""}</span>;
};

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props} sx={{ display: "flex", justifyContent: "space-between" }}>
    <SaveButton />
    <DeleteWithConfirmButton
      label="Deactivate"
      confirmTitle="Deactivate member"
      confirmContent="Are you sure you want to deactivate this member's account? This will lockout the user from accessing the syndicate admin."
    />
  </Toolbar>
);

export const MemberEdit = (props: any) => (
  <Edit title={<MemberTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="display_name" label="Name" />
      <TextInput type="email" source="email" />
      {/* <SelectArrayInput source="roles" choices={roleChoices} /> */}
      {/* <ImageInput source="avatar" >
        <ImageField source="src" title="title" />
      </ImageInput> */}
    </SimpleForm>
  </Edit>
);

// const roleChoices = [
//   {name: "Admin", id: "*:1"},
//   {name: "Engineer", id: "handoverReport:1"},
//   {name: "Bookings Manager", id: "bookings:1"},
//   {name: "Member", id: "*:2"},
// ];

export const MemberCreate = (props: any) => (
  <Create title="Add a Member" {...props}>
    <SimpleForm>
      <TextInput source="display_name" label="Name" />
      <TextInput type="email" source="email" />
      <TextInput type="password" source="password" />
      {/* <SelectArrayInput source="roles" defaultValue={["member"]} choices={roleChoices} /> */}
      {/* <ImageInput source="avatar" >
        <ImageField source="src" title="title" />
      </ImageInput> */}
    </SimpleForm>
  </Create>
);
