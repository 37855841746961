import { useState } from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  DateField,
  EditButton,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  useRecordContext,
  required,
  useGetIdentity,
  FormDataConsumer,
  ButtonProps,
  useSimpleFormIterator,
  Button,
  AutocompleteInput,
  useSimpleFormIteratorItem,
  ReferenceField,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  ShowButton,
  number,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import {
  CreateInDialogButton,
  AccordionForm,
  AccordionFormPanel,
} from "@react-admin/ra-form-layout";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { IssueForm } from "../issues";
import RichTextInput from "../../fields/RichTextInput";
import IfCanAccess from "../../auth/IfCanAccess";

export const HandoverReportIcon = HandshakeIcon;
export const HandoverReportResource = "handoverReport";

export const HandoverReportList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <DateField source="handoverDate" locales="en-GB" />
      <ReferenceField reference="users" source="host" link={false} />
      <ReferenceField reference="users" source="handoverTo" link={false} />
      <ShowButton label="View" />
      <IfCanAccess action="edit">
        <EditButton />
      </IfCanAccess>
    </Datagrid>
  </List>
);

export const HandoverReportTitle = () => {
  const record = useRecordContext();
  return <span>Handover Report {record ? `#${record.id}` : ""}</span>;
};

const styles = {
  issue: { display: "flex", alignItems: "center" },
  createIssueButton: { marginLeft: "1rem", marginTop: "-1rem" },
  metricGroup: {
    display: "flex",
    width: "100%",
  },
  metricComment: { marginLeft: "1rem", flex: "2" },
};

export const HandoverReportForm = (props: any) => {
  const identity = useGetIdentity();
  const [issues, setIssues] = useState<{ id: string; value: string }[]>([]);

  /**
   * Restricts number inputs to numbers as Firefox doesn't natively support 
   * that like Chrome
   */
  const restrictToNumeric = (e: any) => {
    if (e.target.type === "number" && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  };

  return (
    <AccordionForm autoClose>
      <AccordionFormPanel label="Trip Details">
        <TextInput
          style={{ display: "none" }}
          disabled
          source="host"
          defaultValue={identity.identity?.id}
        />
        <DateInput
          source="handoverDate"
          defaultValue={new Date()}
          parse={(v: string | Date) => v instanceof Date ? v : new Date(v)}
          validate={[required()]}
        />
        <ReferenceInput
          reference="users"
          source="handoverTo"
          allowEmpty={true}
        />
        <ArrayInput source="crewMembers">
          <SimpleFormIterator inline>
            <TextInput source="name" helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </AccordionFormPanel>

      <FormDataConsumer>
        {({ formData }) => {
          const stateIsRequired = formData && !formData.handoverTo;
          return (
            <AccordionFormPanel
              label="State"
              secondary={
                !stateIsRequired
                  ? "Details of boat status are not required when handing over directly."
                  : "Details of boat status are required when not handing over directly."
              }
              disabled={!stateIsRequired}
            >
              <RadioButtonGroupInput
                source="statusLandlineConnected"
                label="Landline"
                validate={stateIsRequired ? required() : undefined}
                choices={[
                  { id: "connected", name: "Connected" },
                  { id: "Disconnected", name: "Disconnected" },
                ]}
              />
              <RadioButtonGroupInput
                source="statusInvertor"
                label="Invertor"
                validate={stateIsRequired ? required() : undefined}
                choices={[
                  { id: "on", name: "On" },
                  { id: "off", name: "Off" },
                ]}
              />
              <RadioButtonGroupInput
                source="statusBatteryCharger"
                label="Battery Charger"
                validate={stateIsRequired ? required() : undefined}
                choices={[
                  { id: "on", name: "On" },
                  { id: "off", name: "Off" },
                ]}
              />
              <div>
                <RadioButtonGroupInput
                  source="statusFridge"
                  label="Fridge"
                  validate={stateIsRequired ? required() : undefined}
                  choices={[
                    { id: "on", name: "On" },
                    { id: "off", name: "Off" },
                  ]}
                />

                <CheckboxGroupInput
                  sx={{
                    paddingTop: "1.4rem",
                    ".MuiFormControlLabel-root": { alignItems: "flex-end" },
                  }}
                  source="statusFridgeDefrosted"
                  label=""
                  choices={[{ id: "defrost", name: "Defrosted" }]}
                />
              </div>
              <RadioButtonGroupInput
                sx={{}}
                source="statusGas"
                label="Gas"
                validate={stateIsRequired ? required() : undefined}
                choices={[
                  { id: "on", name: "On" },
                  { id: "off", name: "Off" },
                ]}
              />
              <RadioButtonGroupInput
                source="statusToiletCompressor"
                label="Toilet Compressor"
                validate={stateIsRequired ? required() : undefined}
                choices={[
                  { id: "on", name: "On" },
                  { id: "off", name: "Off" },
                ]}
              />
              <RadioButtonGroupInput
                source="statusWaterPump"
                label="Water Pump"
                validate={stateIsRequired ? required() : undefined}
                choices={[
                  { id: "on", name: "On" },
                  { id: "off", name: "Off" },
                ]}
              />
            </AccordionFormPanel>
          );
        }}
      </FormDataConsumer>

      <AccordionFormPanel
        label="Maintenance Metrics"
        secondary="Add any relevant maintenance metrics for the engineer's record."
      >
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricEngineHrStart"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Engine Hours at Start"
          />
          <TextInput
            style={styles.metricComment}
            source="commentsEngineHrStart"
            label="Comment"
          />
        </div>
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricEngineHrEnd"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Engine Hours at End"
          />
          <TextInput
            source="commentsEngineHrEnd"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricEngineOilUsed"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Engine Oil Used (L)"
          />
          <TextInput
            source="commentsEngineOilUsed"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricGearboxOilUsed"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Gearbox Oil Used (L)"
          />
          <TextInput
            source="commentsGearboxOilUsed"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricDieselFuelUsed"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Diesel Fuel Used (L)"
          />
          <TextInput
            source="commentsDieselFuelUsed"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricCoolantAdded"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Coolant Added (L)"
          />
          <TextInput
            source="commentsCoolantAdded"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
        <div style={styles.metricGroup}>
          <NumberInput
            source="metricGasPurchased"
            defaultValue={0}
            validate={[required(), number()]}
            onKeyPress={restrictToNumeric}
            label="Gas Purchased (No.)"
          />
          <TextInput
            source="commentsGasPurchased"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
        <div style={styles.metricGroup}>
          <BooleanInput
            source="metricWaterFilterCleaned"
            label="Water Filter Cleaned"
          />
          <TextInput
            source="commentsWaterFitlerCleaned"
            label="Comment"
            style={styles.metricComment}
          />
        </div>
      </AccordionFormPanel>

      <AccordionFormPanel
        label="Issues"
        secondary="Attach existing issues to your handover report or create a new issue."
      >
        <ArrayInput source="issues" label="">
          <SimpleFormIterator
            addButton={<AddIssueButton label="Attach Issue" />}
          >
            <Issue issues={issues} setIssues={setIssues} />
          </SimpleFormIterator>
        </ArrayInput>
      </AccordionFormPanel>

      <AccordionFormPanel label="Notes">
        <TextInput
          source="notesComments"
          label="Other comments"
          multiline
          fullWidth
        />
      </AccordionFormPanel>
    </AccordionForm>
  );
};

const Issue = ({ issues, setIssues, ...props }: any) => {
  const item = useSimpleFormIteratorItem();
  const { setValue } = useFormContext();
  return (
    <FormDataConsumer {...props}>
      {({ scopedFormData, getSource }) => {
        return (
          <>
            <div style={styles.issue}>
              <ReferenceInput
                source={getSource ? getSource("issue") : "issue"}
                reference="issues"
                record={scopedFormData || {}}
                filter={{ status: "open" }}
              >
                <AutocompleteInput
                  label="Select Issue"
                  filterToQuery={(searchText: string) => ({})}
                />
              </ReferenceInput>
              <div style={styles.createIssueButton}>
                <CreateInDialogButton
                  label="Create new issue"
                  title="Create new issue"
                  resource="issues"
                >
                  <IssueForm
                    onSubmit={(data: any) => {
                      setValue(`issues.${item.index}.issue`, data.id);
                      setValue(`issues.${item.index}.comment`, data.comment);
                      setValue(`issues.${item.index}.issueCreatedInline`, true);
                    }}
                  />
                </CreateInDialogButton>
              </div>
            </div>
            {scopedFormData?.issue && (
              <RichTextInput
                source={getSource ? getSource("comment") : "comment"}
                disabled={scopedFormData.issueCreatedInline}
              />
            )}
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export const HandoverReportEdit = (props: any) => (
  <Edit title={<HandoverReportTitle />} redirect="show" {...props}>
    <HandoverReportForm />
  </Edit>
);

export const HandoverReportCreate = (props: any) => (
  <Create title="Create a Handover Report" redirect="show" {...props}>
    <HandoverReportForm />
  </Create>
);

const AddIssueButton = (props: ButtonProps) => {
  const { add } = useSimpleFormIterator();
  return (
    <Button
      label="Add"
      size="small"
      onClick={() => add()}
      color="primary"
      {...props}
    >
      <AddIcon fontSize="small" />
    </Button>
  );
};
