import React from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import polyglotI18nProvider from "ra-i18n-polyglot";
import {
  DocumentList,
  DocumentEdit,
  DocumentCreate,
  DocumentIcon,
} from "./resources/documents/documents";
import {
  HandoverReportList,
  HandoverReportEdit,
  HandoverReportCreate,
  HandoverReportIcon,
  HandoverReportResource,
  HandoverReportShow,
} from "./resources/handoverReport";
import {
  IssueList,
  IssueEdit,
  IssueCreate,
  IssueIcon,
  IssueShow,
  IssueResource,
} from "./resources/issues";
import {
  MemberList,
  MemberEdit,
  MemberCreate,
  MemberIcon,
  MemberResource,
} from "./resources/members";
import Dashboard from "./dashboard/Dashboard";
import Layout from "./layout/Layout";
import ProfileEdit from "./auth/Profile";
import theme from "./theme";

import dataProvider from "./dataProvider";
import { authProvider } from "./firebase";
import LoginPage from "./auth/LoginPage";
import messages from "./i18n/english";

const i18nProvider = polyglotI18nProvider(() => messages);

function App() {
  return (
    <Admin
      title="Firstmate"
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      theme={theme}
      loginPage={LoginPage}
      layout={Layout}
      i18nProvider={i18nProvider}
      requireAuth
    >
      <CustomRoutes>
        <Route path="/profile" element={<ProfileEdit />} />
      </CustomRoutes>
      <Resource
        name="documents"
        options={{ label: "Info" }}
        list={DocumentList}
        edit={DocumentEdit}
        create={DocumentCreate}
        icon={DocumentIcon}
      />
      <Resource
        name={HandoverReportResource}
        options={{ label: "Handover Reports" }}
        list={HandoverReportList}
        edit={HandoverReportEdit}
        show={HandoverReportShow}
        create={HandoverReportCreate}
        icon={HandoverReportIcon}
      />
      <Resource
        name={IssueResource}
        options={{ label: "Issue Log" }}
        list={IssueList}
        edit={IssueEdit}
        show={IssueShow}
        create={IssueCreate}
        icon={IssueIcon}
        recordRepresentation={(record) => record.title}
      />
      <Resource name="comments" />
      <Resource
        name={MemberResource}
        options={{ label: "Members" }}
        list={MemberList}
        edit={MemberEdit}
        create={MemberCreate}
        icon={MemberIcon}
        recordRepresentation={(record) => `${record.display_name}`}
      />
    </Admin>
  );
}

export default App;
