import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { useTranslate } from "react-admin";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default function AlertDialog() {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [toastOpen, setToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setOpen(false);
      setToastOpen(true);
      setToastMessage(translate("auth.reset.confirmMessage"));
    } catch (error: any) {
      setToastOpen(true);
      setToastMessage(error.message);
    }
  };

  const handleOnChange = (event: any) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleToastClose = () => {
    setToastOpen(false);
    setToastOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        paddingTop: "0px",
      }}
    >
      <Button
        variant="text"
        onClick={handleClickOpen}
        style={{ width: "100%" }}
      >
        {translate("auth.reset.link")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate("auth.reset.dialogTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {translate("auth.reset.dialogText")}
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label={translate("ra.auth.username")}
            type="email"
            variant="outlined"
            style={{ width: "100%" }}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate("ra.action.cancel")}</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
          {translate("auth.reset.dialogAction")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        message={toastMessage}
      ></Snackbar>
    </div>
  );
}
