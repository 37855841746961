import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  ReferenceField,
  RichTextField,
  SelectField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
} from "react-admin";
import { Typography, Divider } from "@mui/material";
import { HandoverReportTitle } from "./handoverReport";
import IfCanAccess from "../../auth/IfCanAccess";

const ReportShowActions = () => (
  <TopToolbar>
    <IfCanAccess action="edit">
      <EditButton />
    </IfCanAccess>
  </TopToolbar>
);

export const HandoverReportShow = (props: any) => (
  <Show title={<HandoverReportTitle />} actions={<ReportShowActions/>} {...props}>
    <HandoverReportView />
  </Show>
);

export const HandoverReportView = (props: any) => {
  return (
    <SimpleShowLayout>
      <Typography variant="h5">Trip Details</Typography>
      <DateField source="handoverDate" locales="en-GB" />
      <ReferenceField reference="users" source="host" link={false} />
      <ReferenceField reference="users" source="handoverTo" emptyText="None" link={false} />
      <ArrayField source="crewMembers">
        <SingleFieldList linkType={false}>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ArrayField>
      <Divider />
      <Typography variant="h5">State</Typography>
      <SelectField
        label="Landline"
        source="statusLandlineConnected"
        choices={[
          { id: "connected", name: "Connected" },
          { id: "Disconnected", name: "Disconnected" },
        ]}
      />
      <SelectField
        label="Invertor"
        source="statusInvertor"
        choices={toggleChoices}
      />
      <SelectField
        label="Fridge"
        source="statusFridge"
        choices={toggleChoices}
      />
      <BooleanField label="Fridge Defrosted" source="statusFridgeDefrosted" />
      <SelectField label="Gas" source="statusGas" choices={toggleChoices} />
      <SelectField
        label="Toilet Compressor"
        source="statusToiletCompressor"
        choices={toggleChoices}
      />
      <SelectField
        label="Water Pump"
        source="statusWaterPump"
        choices={toggleChoices}
        emptyText="-"
      />
      <Divider />
      <Typography variant="h5">Metrics</Typography>
      <NumberField
        source="metricEngineHrStart"
        defaultValue={0}
        label="Engine Hours at Start"
      />
      <TextField source="commentsEngineHrStart" label={false} />
      <NumberField
        source="metricEngineHrEnd"
        defaultValue={0}
        label="Engine Hours at End"
      />
      <TextField source="commentsEngineHrEnd" label={false} />
      <NumberField
        source="metricEngineOilUsed"
        defaultValue={0}
        label="Engine Oil Used (L)"
      />
      <TextField source="commentsEngineOilUsed" label={false} />
      <NumberField
        source="metricGearboxOilUsed"
        defaultValue={0}
        label="Gearbox Oil Used (L)"
      />
      <TextField source="commentsGearboxOilUsed" label={false} />
      <NumberField
        source="metricDieselFuelUsed"
        defaultValue={0}
        label="Diesel Fuel Used (L)"
      />
      <TextField source="commentsDieselFuelUsed" label={false} />
      <NumberField
        source="metricCoolantAdded"
        defaultValue={0}
        label="Coolant Added (L)"
      />
      <TextField source="commentsCoolantAdded" label={false} />
      <NumberField
        source="metricGasPurchased"
        defaultValue={0}
        label="Gas Purchased (No.)"
      />
      <TextField source="commentsGasPurchased" label={false} />
      <BooleanField
        source="metricWaterFilterCleaned"
        label="Water Filter Cleaned"
      />
      <TextField source="commentsWaterFitlerCleaned" label={false} />
      <Divider />
      <Typography variant="h5">Issues</Typography>
      <ArrayField source="issues" label="">
        <Datagrid bulkActionButtons={false}>
          <ReferenceField source="issue" reference="issues" />
          <RichTextField source="comment" />
        </Datagrid>
      </ArrayField>
      <Divider />
      <Typography variant="h5">Notes</Typography>
      <TextField source="notesComments" label="Other comments" fullWidth />
    </SimpleShowLayout>
  );
};

const toggleChoices = [
  { id: "on", name: "On" },
  { id: "off", name: "Off" },
];
