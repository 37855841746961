import {
  Button,
  Confirm,
  DeleteWithConfirmButtonProps,
  ReferenceField,
  RichTextField,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDeleteWithConfirmController,
  useRecordContext,
  useResourceContext,
  useTranslate,
  useUpdate,
} from "react-admin";
import { Typography, Chip, Card, CardContent, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TimeAgoField from "../../fields/TimeAgoField";
import IfCanAccess from "../../auth/IfCanAccess";
import { useState } from "react";
import RichTextInput from "../../fields/RichTextInput";

export const CommentCard = ({ bodySource = "body", enableDelete = true }: any) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const [edit, setEdit] = useState(false);
  const [update] = useUpdate();
  function updateComment(data: any) {
    update(resource, { id: data.id, data }).then(() => setEdit(false));
  }
  return (
    <>
      {record.reportId && (
        <div className="timeline__item timeline__item--reference">
          <ReferenceField link={false} reference="users" source="createdBy" />
          &nbsp;
          <Typography variant="body2" sx={{ display: "inline-block" }}>
            referenced this issue in handover report
          </Typography>
          <Chip
            size="small"
            label={
              <ReferenceField reference="handoverReport" source="reportId" />
            }
          />
          &nbsp;
          <TimeAgoField
            source="createdAt"
            variant="body2"
            sx={{ display: "inline-block" }}
          />
        </div>
      )}
      {record?.action === "open" && (
        <div className="timeline__item timeline__item--reference">
          <ReferenceField link={false} reference="users" source="createdBy" />
          &nbsp;
          <Typography variant="body2" sx={{ display: "inline-block" }}>
            re-opened this issue
          </Typography>
          &nbsp;
          <TimeAgoField
            source="createdAt"
            variant="body2"
            sx={{ display: "inline-block" }}
          />
        </div>
      )}
      {record && record[bodySource] && (
        <div className="timeline__item">
          <Card
            variant="outlined"
            sx={{
              "& .MuiTypography-body2": { color: "rgb(87,96,106)" },
            }}
          >
            <div className="comment__header">
              <ReferenceField
                link={false}
                reference="users"
                source="createdBy"
              />
              &nbsp;
              <Typography variant="body2" sx={{ display: "inline-block" }}>
                commented
              </Typography>
              &nbsp;
              <TimeAgoField
                source="createdAt"
                variant="body2"
                sx={{ display: "inline-block" }}
              />
              <IfCanAccess action="edit">
                <IconButton
                  size="small"
                  sx={{ float: "right" }}
                  onClick={() => setEdit(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                {enableDelete && <DeleteIconButton redirect={false}/>}
              </IfCanAccess>
            </div>
            <CardContent>
              {edit ? (
                <SimpleForm
                  sx={{ "&.MuiCardContent-root": { paddingBottom: 0 } }}
                  onSubmit={updateComment}
                  toolbar={
                    <Toolbar
                      sx={{
                        background: "none",
                        "&.MuiToolbar-root": {
                          minHeight: 0,
                          paddingLeft: "1rem",
                        },
                      }}
                    >
                      <SaveButton
                        size="small"
                        icon={<></>}
                        label="Save comment"
                      />
                      <Button
                        size="small"
                        label="Cancel"
                        onClick={() => setEdit(false)}
                        sx={{ marginLeft: "1rem" }}
                      />
                    </Toolbar>
                  }
                >
                  <RichTextInput label=" " source={bodySource} />
                </SimpleForm>
              ) : (
                <RichTextField source={bodySource} />
              )}
            </CardContent>
          </Card>
        </div>
      )}
      {record?.action === "closed" && (
        <div className="timeline__item timeline__item--reference">
          <ReferenceField link={false} reference="users" source="createdBy" />
          &nbsp;
          <Typography variant="body2" sx={{ display: "inline-block" }}>
            closed this issue
          </Typography>
          &nbsp;
          <TimeAgoField
            source="createdAt"
            variant="body2"
            sx={{ display: "inline-block" }}
          />
        </div>
      )}
    </>
  );
};

export default CommentCard;

const DeleteIconButton = (props: DeleteWithConfirmButtonProps) => {
  const {
    className,
    confirmTitle = "ra.message.delete_title",
    confirmContent = "ra.message.delete_content",
    icon = <DeleteIcon fontSize="small" />,
    mutationMode = "pessimistic",
    onClick,
    redirect = "list",
    translateOptions = {},
    mutationOptions,
    ...rest
  } = props;
  const translate = useTranslate();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  const { open, isLoading, handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      record,
      redirect,
      mutationMode,
      onClick,
      mutationOptions,
      resource,
    });
  return (
    <>
      <IconButton
        size="small"
        sx={{ float: "right" }}
        onClick={handleDialogOpen}
      >
        {icon} 
      </IconButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.name`, {
            smart_count: 1,
          }),
          id: record.id,
          ...translateOptions,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  );
};
